import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { Router } from '../../common/router';
import { FEED_PAGE_NOT_FOUND } from '../../common/services/detect-route';
import { AppStore } from '../../common/types';
import {
  ROUTE_404,
  ROUTE_ARCHIVE,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_HASHTAG,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_SEARCH,
  ROUTE_TAGS,
} from '../constants/routes';
import { createArchivePageRouter } from './create-routers/create-archive-page-router';
import { createCategoryPageRouter } from './create-routers/create-category-page-router';
import { createFeedPageRouter } from './create-routers/create-feed-page-router';
import { createHashtagPageRouter } from './create-routers/create-hashtag-page-router';
import { createSearchPageRouter } from './create-routers/create-search-page-router';
import { createTagPageRouter } from './create-routers/create-tag-page-router';
import type { IWixCodeApi } from '../../common/controller/wix-code-api';

export const createRouter = (
  store: AppStore,
  _config: unknown,
  wixCodeApi: IWixCodeApi,
) => {
  const router = new Router();

  router.add(ROUTE_TAGS, createTagPageRouter(store, wixCodeApi), {
    preFetch: true,
  });
  router.add(ROUTE_CATEGORIES, createCategoryPageRouter(store, wixCodeApi), {
    preFetch: true,
  });

  router.add(ROUTE_CATEGORY, createCategoryPageRouter(store, wixCodeApi), {
    preFetch: true,
  });
  router.add(ROUTE_SEARCH, createSearchPageRouter(store, wixCodeApi));
  router.add(ROUTE_HASHTAG, createHashtagPageRouter(store, wixCodeApi), {
    preFetch: true,
  });
  router.add(ROUTE_ARCHIVE, createArchivePageRouter(store, wixCodeApi), {
    preFetch: true,
  });
  router.add(
    ROUTE_HOME,
    createFeedPageRouter({
      store,
      wixCodeApi,
    }),
    { preFetch: true },
  );
  router.add(
    ROUTE_404,
    createNotFoundPageRouter(store, wixCodeApi, FEED_PAGE_NOT_FOUND, ROUTE_404),
  );
  router.add(ROUTE_LOGIN);
  router.fallback(ROUTE_404);
  return router;
};
